import React from 'react';

const Innovation = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="64" viewBox="0 0 65 64" fill="none">
    <circle opacity="0.56" cx="31.6667" cy="12.6666" r="6.66667" transform="rotate(-90 31.6667 12.6666)" fill="#5F39F8" fill-opacity="0.17"/>
    <circle opacity="0.56" cx="32.5" cy="47.5" r="3.5" transform="rotate(-180 32.5 47.5)" fill="#5F39F8" fill-opacity="0.17"/>
    <mask id="mask0_4515_12407" maskUnits="userSpaceOnUse" x="6" y="5" width="53" height="54">
      <rect x="6.17773" y="5.58105" width="52.8224" height="52.8224" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_4515_12407)">
      <path d="M18.2829 44.0975C14.9081 44.0975 12.0469 42.9237 9.69923 40.576C7.35157 38.2283 6.17773 35.3671 6.17773 31.9924C6.17773 28.6176 7.35157 25.7564 9.69923 23.4087C12.0469 21.061 14.9081 19.8872 18.2829 19.8872C19.6401 19.8872 20.9423 20.1256 22.1895 20.6025C23.4367 21.0794 24.5555 21.758 25.546 22.6384L29.2876 26.0498L25.9862 29.0211L22.5747 25.9398C21.9878 25.4262 21.3275 25.0227 20.5939 24.7293C19.8602 24.4358 19.0899 24.2891 18.2829 24.2891C16.1553 24.2891 14.3395 25.0411 12.8356 26.545C11.3316 28.049 10.5796 29.8648 10.5796 31.9924C10.5796 34.1199 11.3316 35.9357 12.8356 37.4397C14.3395 38.9436 16.1553 39.6956 18.2829 39.6956C19.0899 39.6956 19.8602 39.5489 20.5939 39.2554C21.3275 38.962 21.9878 38.5585 22.5747 38.0449L39.632 22.6384C40.6224 21.758 41.7412 21.0794 42.9884 20.6025C44.2356 20.1256 45.5378 19.8872 46.895 19.8872C50.2698 19.8872 53.131 21.061 55.4787 23.4087C57.8264 25.7564 59.0002 28.6176 59.0002 31.9924C59.0002 35.3671 57.8264 38.2283 55.4787 40.576C53.131 42.9237 50.2698 44.0975 46.895 44.0975C45.5378 44.0975 44.2356 43.8591 42.9884 43.3822C41.7412 42.9053 40.6224 42.2267 39.632 41.3463L35.8904 37.9349L39.1918 34.9636L42.6032 38.0449C43.1901 38.5585 43.8504 38.962 44.5841 39.2554C45.3177 39.5489 46.088 39.6956 46.895 39.6956C49.0226 39.6956 50.8384 38.9436 52.3424 37.4397C53.8463 35.9357 54.5983 34.1199 54.5983 31.9924C54.5983 29.8648 53.8463 28.049 52.3424 26.545C50.8384 25.0411 49.0226 24.2891 46.895 24.2891C46.088 24.2891 45.3177 24.4358 44.5841 24.7293C43.8504 25.0227 43.1901 25.4262 42.6032 25.9398L25.546 41.3463C24.5555 42.2267 23.4367 42.9053 22.1895 43.3822C20.9423 43.8591 19.6401 44.0975 18.2829 44.0975Z" fill="url(#paint0_linear_4515_12407)"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_4515_12407" x1="44.5002" y1="6.00049" x2="19.9462" y2="50.1929" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4000FF"/>
        <stop offset="1" stop-color="#FF2FEA" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
  );
};

export default Innovation;
