import React from "react";
import { AlphaMetricXAIText } from "../../../constants/mock";
import './index.css'

const AlphaMetricxAI = () => {
  return (
    <div className="alpha-container">
        { AlphaMetricXAIText?.map((item, i) => (
      <div className={`alpha-wrp ${i % 2 !== 0 ? 'reverse-wrp' : ''}`} key={i}>
        <div className="content-wrp">
          <div className="title-content">{item?.title}</div>
          <div className="descp-content">{item?.description}</div>
        </div>
        <img src={item?.image} alt="" className="alpha-image" />
      </div>
))}
    </div>
  );
};

export default AlphaMetricxAI;
