import React from 'react';

const Relevance = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="64" viewBox="0 0 65 64" fill="none">
    <mask id="mask0_4515_12398" maskUnits="userSpaceOnUse" x="7" y="2" width="50" height="49">
      <rect x="7.27979" y="2.13623" width="48.8638" height="48.8638" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_4515_12398)">
      <path d="M10.4865 23.6669L7.27979 20.4602C10.4356 17.2366 14.1343 14.734 18.3759 12.9525C22.6176 11.171 27.0628 10.2803 31.7117 10.2803C32.5261 10.2803 33.4423 10.3057 34.4603 10.3566C35.4783 10.4075 36.5302 10.5008 37.616 10.6366L35.4783 15.014C34.8675 14.9461 34.2567 14.9037 33.6459 14.8867C33.0351 14.8697 32.3903 14.8613 31.7117 14.8613C27.6736 14.8613 23.8477 15.6417 20.2338 17.2026C16.6199 18.7636 13.3708 20.9183 10.4865 23.6669ZM16.7471 29.6731L13.5405 26.4664C15.8818 24.1929 18.6898 22.4623 21.9644 21.2746C25.2389 20.087 28.9631 19.6289 33.1369 19.9003L31.1518 24.0232C28.5389 23.9553 25.9176 24.4474 23.2878 25.4993C20.6579 26.5512 18.4777 27.9425 16.7471 29.6731ZM29.8793 42.6525C28.9631 42.2793 28.2929 41.5836 27.8687 40.5656C27.4446 39.5476 27.47 38.5466 27.9451 37.5625L40.0083 12.6217C40.1101 12.3841 40.2883 12.2145 40.5428 12.1127C40.7973 12.0109 41.0603 11.9939 41.3317 12.0618C41.6032 12.1296 41.8238 12.2823 41.9934 12.5199C42.1631 12.7574 42.214 13.0119 42.1461 13.2834L35.122 40.5147C34.8505 41.6006 34.1549 42.3217 33.0351 42.678C31.9153 43.0343 30.8633 43.0258 29.8793 42.6525ZM46.6762 29.6731C46.1333 29.0962 45.3783 28.4685 44.4112 27.7898C43.4441 27.1111 42.6212 26.6191 41.9425 26.3137L43.0114 21.9363C44.1991 22.3435 45.4376 22.9967 46.7271 23.896C48.0166 24.7952 49.0685 25.652 49.8829 26.4664L46.6762 29.6731ZM52.9369 23.6669C51.6135 22.3775 50.1713 21.2068 48.6104 20.1548C47.0495 19.1029 45.5564 18.2546 44.1312 17.6098L45.251 13.0289C47.4567 13.9451 49.4248 15.0224 51.1554 16.261C52.886 17.4996 54.5487 18.8993 56.1436 20.4602L52.9369 23.6669Z" fill="url(#paint0_linear_4515_12398)"/>
    </g>
    <circle opacity="0.56" cx="55" cy="34" r="4" transform="rotate(-90 55 34)" fill="#5F39F8" fill-opacity="0.17"/>
    <circle opacity="0.56" cx="4.5" cy="25.5" r="2.5" transform="rotate(-180 4.5 25.5)" fill="#5F39F8" fill-opacity="0.17"/>
    <defs>
      <linearGradient id="paint0_linear_4515_12398" x1="25.848" y1="3.58556" x2="24.5363" y2="41.3626" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4000FF"/>
        <stop offset="1" stop-color="#FF2FEA" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
  );
};

export default Relevance;
