import React from 'react';
import Header from '../header';
import Footer from '../footer';
import './index.css';


const TermsConditions = () => {
  return (
    <div>
      <div className='private-terms-conditions'>
        <div className='private-terms-conditions-header'>
          <Header />
          <h5 className='terms-conditions-head'>Terms & Conditions</h5>
        </div>
        <div className='terms-conditions-content-container'>
          <div>
            <p className='terms-conditions-content'>
              The Terms and Conditions of Use ("Terms") outlined here are the rules and regulations that govern the use of this website, provided by AlphaMetricx. Please read the Terms carefully before using the website. By accessing and using this website, you agree to be bound by these Terms. If you do not wish to be bound by these Terms in part or in full then we recommend you do not use this website.
              These Terms take effect from the date when you first access this website.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Limited License</h2>
            <p className='terms-conditions-text'>
              You are granted a non-exclusive, nontransferable, revocable, limited license to access and use this website and its content. AlphaMetricx reserves the right to terminate this license at any time for any reason as deemed necessary.
            </p>
            <p className='terms-conditions-text'> Any unauthorized use of this use website or its content is prohibited.</p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Limitations on Use</h2>
            <p className='terms-conditions-text'>
              The content on this website is for personal use only and not for commercial exploitation. It should not be used for determining consumer eligibility for credit, insurance, employment, or government licenses. You must refrain from decompiling, reverse engineering, disassembling, renting, leasing, loaning, selling, sublicensing, or creating derivative works from this website or its content. Any such attempt would be addressed by legal remedies initiated by AlphaMetricx.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Third Party Communications</h2>
            <p className='terms-conditions-text'>
              We further disclaim any type of liability for any third-party communications and recommend verifying their source and content. We do no assume any responsibility for verifying, and make no representation of warranties regarding the identity or trustworthiness of the source or content shared via third party communications.
            </p>
            <p className='terms-conditions-text'>We do not make any representations about any other websites or content which may be access through our website.</p>
            <p className='terms-conditions-text'>Any information or opinions shared by our employees or agents during any interactive sessions are their personal views and not necessarily endorsed by the Company.</p>
          </div>

          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Not Legal Advice</h2>
            <p className='terms-conditions-text'>
              The content on this website is not to be construed as any type of legal advice. We do not warrant the accuracy or adequacy of the content on this website. This website does not establish any attorney-client relationship in any way.
            </p>
          </div>

          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Intellectual Property Rights</h2>
            <p className='terms-conditions-text'>
              This website and its content are protected by copyrights, trademarks, and other proprietary rights. Your use of this website and its information in no way constitutes the granting of any license to use any Marks displayed. Unauthorized use of the information and content on this website is prohibited and will be treated as infringement of our ownership.
            </p>
          </div>

          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>No Solicitation</h2>
            <p className='terms-conditions-text'>
              You shall not distribute on or through this website any content, posting, advertisement, promotional offer, or solicitation for others to purchase any goods or service or become member of any enterprise of organization. Any type of promotion or solicitation on this website shall not be carried out without expressed written permission of the company (website owner).
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Advertisements</h2>
            <p className='terms-conditions-text'>
              This website may contain advertising and sponsorship material. We make no warranties of the accuracy and legal compliance of such material. Advertisers and sponsors shall be solely responsible for ensuring that their material is accurate and complies with any legal requirements. We shall also not be responsible for any acts of omissions of advertisers and sponsors.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Registration</h2>
            <p className='terms-conditions-text'>
              Certain sections of this website may require you to register and share your information for us to be able to cater better to your needs. You agree to provide information that is accurate, complete, and up to date. In case of any changes to previously provided information, you shall be responsible for informing us about such changes.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Errors and Corrections</h2>
            <p className='terms-conditions-text'>
              We do not guarantee error-free, virus-free, or accurate content. We retain the right to make improvements or changes to the website and its material as and when deemed fit by us.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Disclaimer</h2>
            <p className='terms-conditions-text'>
              The website, interactive areas, content, and information are provided on “as is, as available” basis. We disclaim all warranties, including warranties of merchantability, fitness for a particular purpose, and non-infringement. We disclaim all responsibility for any loss or damage of any kind that arises from the use of material on this website, any errors or omissions, interactive areas, or material from the website.
              We also disclaim all responsibility for any loss or damage incurred as a result of unavailability of the website.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Limitation of Liability</h2>
            <p className='terms-conditions-text'>
              We shall not be liable for any loss, injury, claim, liability, or damage incurred as a result of use of this website, its content, material, or interactive areas. We shall also not be liable for any special, direct, indirect, incidental, punitive, or consequential damages arising out of the use or inability to use this website, its content, material, or interactive areas.
            </p>
            <p className='terms-conditions-text'>
              In case where the limitation of liability gets prohibited or fails its essential purpose, the obligation of AlphaMetricx to damages shall be limited to $100.00.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Indemnification</h2>
            <p className='terms-conditions-text'>
              You agree to indemnify, defend, and hold AlphaMetricx, it’s officers, employees, and representatives harmless from and against all claims of losses, expenses, and damages, including attorney fees which can result from violations of Terms.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Third-Party Rights</h2>
            <p className='terms-conditions-text'>
              The provisions of Disclaimer, Limitation of Liability, and Indemnification are for the benefit of AlphaMetricx’s officers, employees, and representatives. Each of these entities shall have the right to enforce these clauses directly on AlphaMetricx’s behalf.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Unlawful Activity; Termination of Access</h2>
            <p className='terms-conditions-text'>
              We reserve the right to investigate any purported or reported violations of the Terms and to take any action deemed appropriate including but not limited to reporting of any suspected unlawful activity to law enforcement or regulatory body. During this legal discourse, we may disclose any information required by such legal authority.
            </p>
            <p className='terms-conditions-text'>
              We reserve the right to discontinue any party’s participation in an interactive area at any time as deemed fit.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Remedies for Violations</h2>
            <p className='terms-conditions-text'>
              We reserve the right to seek any and all remedies available at law in case of violations of the Terms. Our right to remedy shall include but will not be limited to our right to block access to certain areas of the website and its features as deemed fit.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Governing Law and Jurisdiction</h2>
            <p className='terms-conditions-text'>
              These Terms are governed by the laws of the State of Texas. Any disputes or action arising out of or relating to these Terms shall be filed only in state or federal courts located in the Dallas County, Texas.
              By continuing to use this website, you hereby consent to the personal jurisdiction of such courts for the purpose of litigation.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Privacy</h2>
            <p className='terms-conditions-text'>
              Your continue use of this website subjects you to our Privacy Policy.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Severability of Provisions</h2>
            <p className='terms-conditions-text'>
              If any provision of these Terms is deemed unlawful or unenforceable, then it shall be deemed severable from the remaining provisions and shall not affect the validity and enforceability of such other provisions.
            </p>
          </div>
          <div className='terms-conditions-wrapper'>
            <h2 className='terms-conditions-heading'>Modifications to Terms of Use</h2>
            <p className='terms-conditions-text'>
              We reserve the right to modify or update the provisions of the Terms at any time. The updated or modified Terms of Use will appear on the website and shall be effective immediately. Your continued use of this website shall constitute acceptance of such changes or updates to the Terms of Use.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
}

export default TermsConditions;
