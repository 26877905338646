import React from "react";
import "./index.css";
import ErrorIcon from "../../assets/icons/ErrorIcon";
import { useNavigate } from "react-router-dom";

const ErrorHandler = ({ resetErrorBoundary }) => {
  const navigate = useNavigate();
  return (
    <div className="error-container">
      <div className="error-import">
        <div className="error-popup">
          <div className="error-icon">
            <ErrorIcon />
          </div>
          <div className="error-message-wpr">
            <div className="error-text-message">Sorry,</div>
            <div className="error-text-message">Something went wrong</div>
          </div>
        </div>
        <div className="error-text">An unexpected error has occurred.</div>
        <button
          className="error-button"
          onClick={() => {
            resetErrorBoundary();
            navigate("/");
          }}
        >
          Go back to home page
        </button>
      </div>
    </div>
  );
};

export default ErrorHandler;
