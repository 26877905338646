import React from 'react';

const Excellence = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="64" viewBox="0 0 65 64" fill="none">
    <mask id="mask0_4515_12416" maskUnits="userSpaceOnUse" x="5" y="5" width="52" height="51">
      <rect x="5.5" y="5" width="50.6668" height="50.6668" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_4515_12416)">
      <path d="M30.8333 49.3334L9.72217 24L16.0555 11.3333H45.6112L51.9445 24L30.8333 49.3334ZM25.2213 22.2407H36.4454L32.7509 14.8517H28.9158L25.2213 22.2407ZM29.0741 41.751V25.7592H15.774L29.0741 41.751ZM32.5926 41.751L45.8927 25.7592H32.5926V41.751ZM40.3686 22.2407H47.0186L43.3241 14.8517H36.6741L40.3686 22.2407ZM14.6481 22.2407H21.2981L24.9926 14.8517H18.3425L14.6481 22.2407Z" fill="url(#paint0_linear_4515_12416)"/>
    </g>
    <circle opacity="0.56" cx="53" cy="7.00024" r="5" transform="rotate(-90 53 7.00024)" fill="#5F39F8" fill-opacity="0.17"/>
    <circle opacity="0.56" cx="13" cy="47.0002" r="3" transform="rotate(-180 13 47.0002)" fill="#5F39F8" fill-opacity="0.17"/>
    <defs>
      <linearGradient id="paint0_linear_4515_12416" x1="25.7666" y1="3.5436" x2="23.7136" y2="47.4562" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4000FF"/>
        <stop offset="1" stop-color="#FF2FEA" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
  );
};

export default Excellence;
