import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


// const firebaseConfig = {
//   apiKey: "AIzaSyCDop_-m40ElnSaMA9BKz11NODUVe3P71E",
//   authDomain: "alphamproject-d510a.firebaseapp.com",
//   projectId: "alphamproject-d510a",
//   storageBucket: "alphamproject-d510a.appspot.com",
//   messagingSenderId: "746227732847",
//   appId: "1:746227732847:web:4bf4c2d8b89d5c5b27189a"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBuNGYVfcMP0yWyDZeuftT_YrnQnaiHB-c",
  authDomain: "alphametricx-d196d.firebaseapp.com",
  projectId: "alphametricx-d196d",
  storageBucket: "alphametricx-d196d.appspot.com",
  messagingSenderId: "887376964614",
  appId: "1:887376964614:web:5ae17cf329f2cb898f2787"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);